import React from "react";
import "./team.css";

export const Team = (props) => {
  return (
    <>
      <div className="u-shape-inverse"></div>
      <div id="team" className="text-center p-5">
        <div className="container">
          <div className="col-md-8 offset-md-2 section-title">
            <h2>{props?.data?.title}</h2>
            <p>{props?.data?.subtitle}</p>
          </div>
          <div className="row">
            {props.data && props.data.components
              ? props.data.components.map((d, i) => (
                  <div
                    key={`${d.name}-${i}`}
                    className="col-md-3 col-sm-6 team"
                  >
                    <div className="thumbnail">
                      {" "}
                      <img src={d.img} alt="..." className="team-img" />
                      <div className="caption">
                        <h4>{d.name}</h4>
                        <p>{d.job}</p>
                      </div>
                    </div>
                  </div>
                ))
              : "loading"}
          </div>
        </div>
      </div>
    </>
  );
};
