import React from "react";
import "./features.css";

export const Features = (props) => {
  return (
    <>
      <section id="features">
        <div className="container p-5">
          <div className="row">
            <div className="col-md-12 section-title text-center">
              <h2>{props.data ? props.data.title : "Loading..."}</h2>
            </div>
          </div>
          <div className="row justify-content-center">
            {props.data
              ? props.data.cards.map((d, i) => (
                  <div
                    key={`${d.title}-${i}`}
                    className="col-sm-6 col-md-4 mb-3 py-3"
                  >
                    <i className={d.icon}></i>
                    <h3 className="d-lg-inline-block">{d.title}</h3>
                    <p>{d.text}</p>
                  </div>
                ))
              : "Loading..."}
            <div className="col-12 text-center text-md-start justify-content-center d-flex">
              <a
                className="btn me-3 btn-lg call-to-action"
                href="#contact"
                role="button"
              >
                {props.data ? props.data.callToAction : "Loading..."}
              </a>
            </div>
          </div>
        </div>
      </section>
      <div className="u-shape"></div>
    </>
  );
};
