import React from "react";

export const SectionCard = ({ section }) => {
  return (
    <div className="col-lg-6 mt-3 p-lg-5">
      <h5 className="fs-4">{section.subtitle}</h5>
      <h2 className="mb-2 fw-bold">{section.title}</h2>
      <p className="mb-4 fw-medium text-secondary">{section.content}</p>
      {section.subsections
        ? section.subsections.map((sub, j) => {
            return (
              <React.Fragment key={"test" + j}>
                <h4 className="fs-3 fw-bold" key={"h4" + j}>
                  {sub.title}
                </h4>
                <p className="mb-4 fw-medium text-secondary" key={"p" + j}>
                  {sub.content}
                </p>
              </React.Fragment>
            );
          })
        : null}
    </div>
  );
};
